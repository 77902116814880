
.activeLink {
  background-color: rgba(255, 255, 255, 0.5) !important; /* Example: White with 50% opacity */
  color: white;
}

:where(.css-dev-only-do-not-override-usln0u).ant-menu-dark, :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu {
  color: rgba(255, 255, 255, 0.65);
  background: #343a40 !important;
}

:where(.css-usln0u).ant-menu-dark, :where(.css-usln0u).ant-menu-dark>.ant-menu {
  color: rgba(255, 255, 255, 0.65);
  background: #343a40 !important;
}
:where(.css-usln0u).ant-menu-dark .ant-menu-item-selected, :where(.css-usln0u).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background: rgba(255, 255, 255, 0.5);

}

:where(.css-usln0u).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-usln0u).ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background: #343a40 !important;

}
:where(.css-dev-only-do-not-override-usln0u).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #343a40 !important;
}

:where(.css-dev-only-do-not-override-usln0u).ant-menu .ant-menu-submenu:hover, :where(.css-dev-only-do-not-override-usln0u).ant-menu .ant-menu-submenu-inline:hover{
  background: rgba(255, 255, 255, 0.5);
}

:where(.css-dev-only-do-not-override-usln0u).ant-menu-dark .ant-menu-item-selected, :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.5);
}


/* :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.5);
} */
/* :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
  background-color: rgba(255, 255, 255, 0.5);

} */
/* .ant-menu-submenu-title .ant-menu-title-content{
  font-size: '16px !important';
}
.ant-menu-submenu .ant-menu-submenu-inline{
  font-size: '16px !important';
} */

a {
  color: rgba(255, 255, 255, 0.65);
}
a:hover{
  color: rgb(235, 235, 235) !important;

}

:where(.css-dev-only-do-not-override-usln0u).ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title{
  background-color: black;
}
/* :where(.css-dev-only-do-not-override-usln0u).ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title{
background-color: rgba(255, 255, 255, 0.5);
}
:where(.css-dev-only-do-not-override-usln0u).ant-menu-dark>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title{
  background-color: rgba(255, 255, 255, 0.5);
} */
/* .ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-open .ant-menu-submenu-selected{
  background-color: rgba(255, 255, 255, 0.5);
} */
 .footer {
position: absolute;
bottom: 0;
width: 100%;
height: 3.5rem;
line-height: 3.5rem;
background-color: #ccc;
}



.nav-link:hover {
transition: all 0.4s;
background: #848486;
color: white;
}

.nav-link-collapse:after {
float: right;
font-family: 'FontAwesome';
}

.nav-link-show:after {
float: right;
font-family: 'FontAwesome';
}

.nav-item ul.nav-second-level {
padding-left: 0;
}

.nav-item ul.nav-second-level > .nav-item {
padding-left: 20px;
}

@media (min-width: 370px) {
.sidenav {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  height: calc(100vh - 3.5rem);
  margin-top: 3.5rem;
  background: #343a40;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.navbar-expand-lg .sidenav {
  flex-direction: column;
}



.footer {
  width: calc(100% - 230px);
  /* margin-left: 230px; */
}
} 
 
 .content-wrapper {
  margin-left: 230px;
} 
.nav-links li.showMenu i.angle {
  transform: rotate(-180deg);
}
.main-sidebar{
  z-index: 999;
  overflow-x: hidden;
}



.subActiveLink {
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: white;
}

.subShow {
  z-index: 10000;
}

